import React from "react"
import { FaHandHoldingHeart } from "react-icons/fa"
import { GiAnatomy, GiThink } from "react-icons/gi"

export default [
  {
    icon: <GiAnatomy />,
    title: "Body",
    text:
      "We move your body",
  },
  {
    icon: <GiThink />,
    title: "Mind",
    text:
      "We activate your mind",
  },
  {
    icon: <FaHandHoldingHeart />,
    title: "Hand",
    text:
      "We are hands on and hands in",
  },
]
